import en from "lang/en.json";
import es from "lang/es.json";
import Head from "next/head";
import React from "react";
import RealestateList from "shared/components-pages/proyectos/RealestateList";
import { IProjectList } from "shared/components-pages/proyectos/ProjectList";
import { CPagesPath } from "shared/models/const/pages-path";
import { useTranslations } from "next-intl";

import type { GetServerSideProps, NextPage } from "next";
import { CBusinessTypes } from "shared/models/const/businessTypes";
import { CPropertyTypes } from "shared/models/const/property-types";
import { useRouter } from "next/router";
import { titleDynamicProperties } from "shared/models/functions/title-dinamic-properties.helper";
import { descriptionDynamicProperties } from "shared/models/functions/description-dinamic-properties.helper";
import Error404 from "./404";
import createCanonicalUrl from "@helpers/removeparams";
import HeadMeta from "@components/head-meta";

export const getServerSideProps: GetServerSideProps = async (context) => {
  const locale = context.locale;
  const params = context.params;

  return {
    props: {
      params: params?.dynamicSEO,
      messages: locale === "es" ? es : en,
    },
  };
};
interface IPropiedad {
  messages: any;
  params: string[];
}

const PropiedadDynamic = (props: IPropiedad) => {
  const titleT = useTranslations("title-pages");
  const router = useRouter();

  const loadParams = (params: any) => {
    const [BusinessType, typeProperty, city, sector] = params;
    let filters: any = {};

    if (BusinessType) {
      filters["businessTypes"] = CBusinessTypes.find(
        (x) => x.label.toLowerCase() == BusinessType.toLowerCase()
      )?.value;
    }

    if (typeProperty) {
      filters["typeProperty"] = [
        CPropertyTypes.find(
          (x) =>
            x.name.toLowerCase() ==
            typeProperty.toLowerCase().replaceAll("-", " ")
        )?.slug,
      ];
    }

    if (city) {
      filters["cityName"] = city;
      filters["locations"] = [
        {
          id: null,
          description: city.replaceAll("-", " ").toUpperCase(),
        },
      ];
    }

    if (sector) filters["sectorName"] = sector;

    return filters;
  };

  let filters: IProjectList = {
    refRequest: "propiedades-list-query",
    detailPath: CPagesPath.es.property_detail,
    filters: loadParams(props.params),
    seoDynamic: true,
  };

  if (
    !filters.filters["typeProperty"] ||
    !filters.filters["typeProperty"][0] ||
    !filters.filters["businessTypes"]
  )
    return <Error404 />;

  const metaData = {
    title:
      titleDynamicProperties(router)?.toUpperCase() +
      "RE/MAX República Dominicana",
    description: descriptionDynamicProperties(router),
    url: process.env.NEXT_PUBLIC_DOMAIN_URL + router.asPath,
    realstateType: props.params[1],
    picture:
      process.env.NEXT_PUBLIC_DOMAIN_URL +
      "/icons/image-optimize/living_meta.jpg",
  };

  return (
    <>
      <Head>
        <title>
          {filters.seoDynamic
            ? titleDynamicProperties(router)?.toUpperCase()
            : titleT("properties")}
        </title>
        <meta name="description" content="" />
        <meta
          name="keywords"
          content="Propiedad exclusivos, proyecto exclusivo en construcción, proyecto exclusivo construido, Propiedad exclusivos remax rd, casas en venta, villas en venta, townhouse en venta, apartamentos en venta, oficinas en venta, almacén en venta, casas en alquiler, villas en alquiler, townhouse en alquiler, apartamento en alquiler, oficinas en alquiler, almacén en alquiler, Propiedad exclusivos de remax república dominicana"
        />
      </Head>
      <Head>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:type" content={metaData.realstateType} />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:image" content={metaData.picture} />
        <link
          rel="canonical"
          href={createCanonicalUrl(metaData.url)}
          key="canonical"
        />
      </Head>

      <RealestateList defaultFilters={filters} />
    </>
  );
};

export default PropiedadDynamic;
