import { NextRouter } from "next/router"
import { CPropertyTypes } from "../const/property-types"
import { seoPathname } from "../const/seo.const"

export const descriptionDynamicProperties = (router: NextRouter) => {
  const params = router.asPath.split('?')[0].split("/")
  const BaseMessage = "Encuentra los mejores inmuebles"
  if (params.length == 6) {
    return `${BaseMessage} de ${params[3]}s en ${params[2]} en ${params[5].replaceAll('-', " ")}, ${params[4].replaceAll('-', " ")} `
  } else if (router.pathname === seoPathname) {
    return BaseMessage + seoDescriptionHandler(params.join('/'));
  }
  else if (params.length == 5) {
    return `${BaseMessage} de ${params[3]}s en ${params[2]} en ${params[4].replaceAll('-', " ")}`
  }
  else if (params.length == 4) {
    return `${BaseMessage} de ${params[3]}s en ${params[2]} `;
  }
  else if (params.length == 3) {
    return `${BaseMessage} ${params[2]}`;
  }
}

export const seoDescriptionHandler = (path: string) => {
  const [_, propertyType, city, sector] = path.substring(1).split("/");
  const propertyTypePlural = CPropertyTypes.find((x) => x.name.toLowerCase() == propertyType.toLocaleLowerCase().replace(/-/g, " "))?.plural;
  let result = " de " + propertyTypePlural;

  if (city) {
    result += " en " + city.replace(/-/g, " ");
    if (sector) result += ", " + sector.replace(/-/g, " ");
  } else {
    result += " en República Dominicana";
  }

  return result;
}

// de Apartamentos de Venta en Piantini, Santo Domingo