import Head from "next/dist/shared/lib/head";
import React from "react";
import { useTranslations } from "next-intl";
import createCanonicalUrl from "@helpers/removeparams";

export interface IHeadMetaComponent {
  title?: keyof Messages["title-pages"];
  description?: keyof Messages["metaDescriptions"];
  url?: string;
  og_type?: string;
  canonical?: string;
  picture?: string;
  keyWords?: string;
}

function HeadMeta(props: IHeadMetaComponent) {
  const { title, description, url, og_type, picture, canonical, keyWords } =
    props;
  const titleT = useTranslations("title-pages");
  const MetaDescriptionT = useTranslations("metaDescriptions");

  return (
    <Head>
      {title && <title>{titleT(title)}</title>}
      {description && <meta name="description" content={description} />}
      {keyWords && <meta name="keywords" content={keyWords} />}
      {url && (
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_DOMAIN_URL}${url}`}
        />
      )}
      {og_type && <meta property="og:type" content={og_type} />}
      {title && <meta property="og:title" content={title} />}
      {description && (
        <meta
          property="og:description"
          content={MetaDescriptionT(description)}
        />
      )}
      {picture && (
        <meta
          property="og:image"
          content={process.env.NEXT_PUBLIC_DOMAIN_URL + picture}
        />
      )}
      {(url || canonical) && (
        <link
          rel="canonical"
          href={createCanonicalUrl(
            `${process.env.NEXT_PUBLIC_DOMAIN_URL}${canonical ?? url}`
          )}
          key="canonical"
        />
      )}
    </Head>
  );
}

export default HeadMeta;
