import styled from "@emotion/styled";
import Link from "next/link";
import React from "react";
import Button from "shared/components/Button";
import HeadMeta, { IHeadMetaComponent } from "shared/components/head-meta";
import { useTranslations } from "next-intl";
import { CKeyWordsPages } from "shared/models/const/keywords.const";
import { useRouter } from "next/router";
import { en, es } from "@lang";

import type { GetStaticPropsContext, NextPage } from "next";
export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: locale === "es" ? es : en,
    },
  };
}

const Error404: NextPage = (props) => {
  const translation = useTranslations("404");
  const router = useRouter();
  const titleT = useTranslations("title-pages");
  const metaData: IHeadMetaComponent = {
    title: "error404",
    description: "page404",
    keyWords: CKeyWordsPages.page404,
    url: router.asPath,
    og_type: "page-no-fount",
  };

  return (
    <ErrorContainer>
      <HeadMeta {...metaData} />
      <article className="error">
        <Title>{titleT("error404")}</Title>
        <div className="container">
          <img
            src="/icons/remaxrd_404.svg"
            alt="404: Página no encontrada"
            className="error__image"
          />
          <div className="content-btn">
            <Link href="/" passHref>
              <Button component="a">{translation("text")}</Button>
            </Link>
          </div>
        </div>
      </article>
    </ErrorContainer>
  );
};

export default Error404;

const ErrorContainer = styled.div`
  .error {
    padding: 100px 0;
  }
  .error__image {
    width: 90%;
    max-width: 700px;
    display: block;
    margin: 0 auto 60px;
  }
  .content-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  color: var(--color-blue-main);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;
